<template>
  <div class="admin-goods_info">
    <div class="cell">
      <div class="title">商品详情</div>
      <div class="conter">
        <div class="conter-item" v-for="(item, i) in goodsData" :key="i">
          <div style="font-weight: 600; width: 100px; text-align: right">
            {{ item.title }}:
          </div>
          <div style="width: 800px; margin-left: 20px">
            <div v-if="item.key == 'description'" style="width: 800px; border: 1px solid #000; padding: 10px">
              <el-input readonly v-model="item.value" type="textarea" show-word-limit maxlength="5000" :autosize="{
                minRows: 2
              }" placeholder="请输入"></el-input>
              <!-- <Editor
                style="height: 300px"
                v-model="item.value"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
              /> -->
            </div>

            <div v-else-if="item.key == 'fm'">
              <span v-for="(val, index) in item.value" :key="index">
                <el-image v-if="val" :preview-src-list="item.value" style="
                    width: 100px;
                    height: 100px;
                    margin-right: 40px;
                    border: 1px solid #eee;
                  " :src="val" fit="contain"></el-image>
              </span>
            </div>
            <div v-else-if="item.key == 'goodsImg'">
              <div v-if="item.value[0]">
                <span v-for="(val, index) in item.value" :key="index">
                  <el-image v-if="val" :preview-src-list="item.value" style="
                      width: 100px;
                      height: 100px;
                      margin-right: 40px;
                      border: 1px solid #eee;
                    " :src="val" fit="contain"></el-image>
                </span>
              </div>
              <div v-else>未上传图片</div>
            </div>
            <div v-else-if="item.key == 'video'">
              <div v-if="item.value.fm || item.value.url" style="
                  width: 100px;
                  height: 100px;
                  position: relative;
                  border: 1px solid #eee;
                ">
                <svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0" class="shopee-svg-icon sFD+z6"
                  style="
                    width: 32px;
                    height: 32px;
                    position: absolute;
                    inset: 0px;
                    margin: auto;
                    z-index: 1;
                    cursor: pointer;
                  " @click="handleVideoPlay">
                  <g opacity=".54">
                    <g>
                      <circle cx="7.5" cy="7.5" fill="#040000" r="7.3"></circle>
                      <path
                        d="m7.5.5c3.9 0 7 3.1 7 7s-3.1 7-7 7-7-3.1-7-7 3.1-7 7-7m0-.5c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5 7.5-3.4 7.5-7.5-3.4-7.5-7.5-7.5z"
                        fill="#ffffff"></path>
                    </g>
                  </g>
                  <path d="m6.1 5.1c0-.2.1-.3.3-.2l3.3 2.3c.2.1.2.3 0 .4l-3.3 2.4c-.2.1-.3.1-.3-.2z" fill="#ffffff">
                  </path>
                </svg>
                <!-- <el-image
                style="width: 100px; height: 100px; background: red"
                :src="item.value.fm"
                fit="contain"
              ></el-image> -->
                <video id="video" :src="item.value.url" style="width: 100px; height: 100px"></video>
              </div>
              <div v-else>未上传视频</div>
            </div>
            <div v-else-if="item.key == 'category_attribute'">
              <div style="
                  display: flex;
                  align-items: center;
                  flex-wrap: wrap;
                  width: 800px;
                ">
                <span v-for="(val, index) in item.value" :key="index">
                  <div v-if="val.value" style="
                      display: flex;
                      align-items: center;
                      margin-right: 40px;
                      padding: 0 0 20px;
                    ">
                    <div style="
                        margin-right: 20px;
                        max-width: 80px;
                        min-width: 80px;
                      ">
                      {{ val.name }}
                    </div>
                    <div style="max-width: 80px; min-width: 80px">
                      {{ val.value }}
                    </div>
                  </div>
                </span>
              </div>
            </div>
            <div v-else-if="item.key == 'sku'">
              <div style="
                  display: inline-block;
                  border: 1px solid #000;

                  font-size: 12px;
                ">
                <div style="display: flex; align-items: center">
                  <div v-for="(val, index) in item.value.head" :key="index" style="
                      width: 100px;
                      max-width: 200px;
                      text-align: center;
                      border-bottom: 1px solid #000;
                      padding: 10px 0;
                      font-weight: 600;
                    " :style="{
                      'border-right':
                        index != item.value.head.length - 1
                          ? '1px solid #000'
                          : '',
                    }">
                    {{ val }}
                  </div>
                </div>
                <div>
                  <div v-for="(val, index) in item.value.list" :key="index" style="
                      display: flex;
                      align-items: center;
                      text-align: center;
                    " :style="{
                      'border-bottom':
                        index != item.value.list.length - 1
                          ? '1px solid #000'
                          : '',
                    }">
                    <div v-if="val.gg1" style="
                        width: 100px;
                        max-width: 200px;
                        border-right: 1px solid #000;
                        padding: 10px 0;
                      ">
                      {{ val.gg1 }}
                    </div>
                    <div v-if="val.gg2" style="
                        width: 100px;
                        max-width: 200px;
                        border-right: 1px solid #000;
                        padding: 10px 0;
                      ">
                      {{ val.gg2 }}
                    </div>
                    <div style="
                        width: 100px;
                        max-width: 200px;
                        border-right: 1px solid #000;
                        padding: 10px 0;
                      ">
                      {{ val.now_price }}
                    </div>
                    <div style="
                        width: 100px;
                        max-width: 200px;
                        border-right: 1px solid #000;
                        padding: 10px 0;
                      ">
                      {{ val.stock }}
                    </div>
                    <div style="width: 100px; max-width: 200px; padding: 10px 0">
                      {{ val.sn ? val.sn : "-" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="item.key == 'goods_condition'">
              {{ item.value == 0 ? "全新" : "二手" }}
            </div>
            <div v-else>
              <span v-if="item.key == 'freight_free'">RM</span>
              {{ item.value }}
              <span v-if="item.key == 'delivery'">天</span>
              <span v-if="item.key == 'freight_weight'">kg</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-button size="small" style="margin: 20px 0" type="primary" @click="handleGo">返回</el-button>
    </div>
  </div>
</template>
<script>
import { goodsInfo } from "@/api/admin.js";
import { Editor } from "@wangeditor/editor-for-vue";
import { brandList } from "@/api/common.js";
export default {
  components: {
    // Editor,
  },
  data() {
    return {
      goodsData: [
        {
          title: "商品名称",
          key: "title",
          value: "",
        },
        {
          title: "商品分类",
          key: "category_name",
          value: "",
        },
        {
          title: "价格",
          key: "price",
          value: "",
        },
        {
          title: "封面主图",
          key: "fm",
          value: "",
        },
        {
          title: "商品图片",
          key: "goodsImg",
          value: "",
        },
        {
          title: "商品视频",
          key: "video",
          value: "",
        },
        {
          title: "商品描述",
          key: "description",
          value: "",
        },

        {
          title: "商品属性",
          key: "category_attribute",
          value: "",
        },

        {
          title: "销售资料",
          key: "sku",
          value: "",
        },

        {
          title: "重量",
          key: "freight_weight",
          value: "",
        },

        {
          title: "包裹尺寸",
          key: "size",
          value: "",
        },

        {
          title: "运费",
          key: "freight_free",
          value: "",
        },

        {
          title: "发货时间",
          key: "delivery",
          value: "",
        },
        {
          title: "商品状态",
          key: "goods_condition",
          value: "",
        },
      ],
      options: [],
      editor: null,
      editorConfig: {
        // 富文本菜单栏配置
        MENU_CONF: {},
        readOnly: true,
      },
      mode: "default", // or 'simple'
    };
  },
  created() {
    this.getGoodsInfoData();

    brandList().then((res) => {
      if (res.code == 1) {
        this.options = res.data;
      }
    });
  },
  mounted() {
    //使用
    window.onresize = function () {
      // 判断是否全屏，全屏播放，缩小暂停
      let isFull =
        document.webkitIsFullScreen ||
        document.mozFullScreen ||
        document.msFullscreenElement ||
        document.fullscreenElement;
      if (isFull == null || isFull == undefined) {
        isFull = false;
      }

      if (isFull) {
        console.log("全屏状态");
      } else {
        let vdo = document.getElementById("video");
        vdo.pause();
      }
    };
  },
  methods: {
    handleGo() {
      this.$router.go(-1);
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    getGoodsInfoData() {
      goodsInfo({
        goods_id: sessionStorage.ADMIN_GOODSID,
      }).then((res) => {
        if (res.code == 1) {
          Object.keys(res.data).forEach((key) => {
            this.goodsData.forEach((val) => {
              if (val.key == key) {
                val.value = res.data[key];
              }

              //   商品封面
              if (val.key == "fm") {
                let arr = [];
                arr.push(res.data.image);
                val.value = arr;
              }
              //   商品图片;
              if (val.key == "goodsImg") {
                val.value = res.data.carousel_image.split(",");
              }
              //   商品视频
              if (val.key == "video") {
                val.value = {
                  fm: res.data.goods_video_cover,
                  url: res.data.goods_video,
                };
              }
              if (val.key == "sku") {
                val.value = {
                  head: [],
                  list: [],
                };
                res.data.spu.forEach((item) => {
                  val.value.head.push(item.name);
                });
                val.value.head.push("价格(RM)");
                val.value.head.push("数量(个)");
                val.value.head.push("规格货号");
                if (res.data.specs != 1) {
                  res.data.sku_list.forEach((item) => {
                    if (item.difference) {
                      item.gg1 = item.difference.split(",")[0];
                      if (item.difference.split(",")[1]) {
                        item.gg2 = item.difference.split(",")[1];
                      }
                    }
                  });
                  val.value.list = res.data.sku_list;
                } else {
                  val.value.list.push({
                    now_price: res.data.price,
                    stock: res.data.stock,
                    sn: "-",
                  });
                }
              }

              if (val.key === "category_attribute") {
                if (val.value.length != 0) {
                  this.options.forEach((item) => {
                    if (item.id == val.value[0].value) {
                      val.value[0].value = item.name;
                    }
                  });
                }
              }
              //   包裹尺寸
              if (val.key == "size") {
                val.value =
                  res.data.size_long +
                  "cm ×" +
                  res.data.size_width +
                  "cm ×" +
                  res.data.size_high +
                  "cm";
              }
            });
          });
        }
      });
    },
    // 视频全屏播放
    handleVideoPlay() {
      let vdo = document.getElementById("video");
      vdo.requestFullscreen();
      vdo.play();
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less">
.admin-goods_info {
  .el-textarea {
    textarea {
      border: none;
      resize: none;
    }
  }

  .cell {
    padding: 20px;
    background: #fff;

    .title {
      font-size: 18px;
      border-bottom: 1px solid #eee;
      padding-bottom: 10px;
    }

    .conter {
      .conter-item {
        font-size: 13px;
        padding: 20px 0;
        display: flex;

        ul {
          list-style: disc;
        }

        ol {
          list-style: decimal;
        }
      }
    }
  }
}
</style>